import React, { useEffect, useState } from "react";
import { omit } from "lodash";
import Amplify, { Auth, API, graphqlOperation, S3Image } from "aws-amplify";
import { Link } from "gatsby-theme-material-ui";
import { useStaticQuery, graphql } from "gatsby";
import { navigate } from "@reach/router";
import {
  makeStyles,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  InputLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  AddBox as CreateIcon,
  Cancel as CancelIcon,
  CheckBoxOutlineBlank as CheckboxBlankIcon,
  CheckBox as CheckboxIcon,
  Refresh as RefreshIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { v4 as uuid } from "uuid";
import {
  createTodo,
  deleteTodo,
  updateTodo,
  createTodoClimate,
  deleteTodoClimate,
  updateTodoClimate,
} from "../../../graphql/mutations";
import {
  getTodo,
  listTodoClimates,
  listCategorys,
  listClimates,
} from "../../../graphql/queries";
import { S3ImageUpload, ImagesList } from "../ImageComponents";

const onCreateTodoImage = `
  subscription OnCreateTodoImage($todoId: ID!) {
    onTodoCreatedTodoImage(todoId: $todoId) {
      id
      todoId
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      createdAt
      updatedAt
    }
  }
`;

const listTodoImagesByTodo = /* GraphQL */ `
  query ListTodoImagesByTodo(
    $todoId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTodoImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodoImagesByTodo(
      todoId: $todoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        todoId
        bucket
        createdAt
        updatedAt
        thumbnail {
          key
          width
          height
        }
      }
      nextToken
    }
  }
`;

const initialState = {
  id: "",
  categoryId: "",
  category: { name: "", id: "" },
  season: "Spring",
  title: "",
  description: "",
  subcontractor: "",
  climateIds: [],
};

const useStyles = makeStyles((theme) => ({
  container: {},
  select: {
    width: 250,
  },
  climate: {},
  input: {
    width: "100%",
  },
}));

const App = ({ id }) => {
  // Fetch placeholder image for uploads
  const placeholderQuery = useStaticQuery(graphql`
    query PlaceholderImageQuery {
      file(relativePath: { eq: "upload_placeholder.png" }) {
        childImageSharp {
          fixed(width: 450) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const placeholderImage = placeholderQuery?.file?.childImageSharp;

  const classes = useStyles();
  const [formState, setFormState] = useState(initialState);
  const [categories, setCategories] = useState([{ name: "", id: "" }]);
  const [climates, setClimates] = useState([{ id: "", description: "" }]);
  const [images, setImages] = useState([]);
  const [errors, setErrors] = useState([]);
  const [oldSubscription, setOldSubscription] = useState();

  const [climateInput, setClimateInput] = useState("");

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
    console.log(formState.$key);
  }

  const compareFunc = (a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    console.log(a, b, dateA, dateB);
    return (dateA > dateB) - (dateA < dateB);
  };

  async function fetchImages(todoId) {
    const listImages = await API.graphql(
      graphqlOperation(listTodoImagesByTodo, { todoId })
    );
    const newImages = listImages?.data?.listTodoImagesByTodo?.items.sort(
      compareFunc
    );
    console.log("Images related to Todo", newImages);
    setImages(newImages);
  }

  async function fetchTodoClimates(todoId) {
    try {
      const climateData = await API.graphql(
        graphqlOperation(listTodoClimates, {
          filter: { todoId: { eq: todoId } },
        })
      );
      const fetchedTodoClimates = climateData.data.listTodoClimates.items;
      console.log("fetchTodoClimates", fetchedTodoClimates);
      return fetchedTodoClimates;
    } catch (err) {
      console.log(`error fetching todoclimate for ${todoId}`, err);
      setErrors([...errors, `Error fetching climate info for Todo ${todoId}`]);
    }
  }

  async function fetchTodo(inputId) {
    try {
      const todoData = await API.graphql(
        graphqlOperation(getTodo, { id: inputId })
      );
      const todo = todoData.data.getTodo;
      const todoClimates = await fetchTodoClimates(id);
      todo.climateIds = todoClimates.map((item) => item.climateId);
      console.log("fetchTodo", todo);
      setFormState(todo);
    } catch (err) {
      console.log("error fetching todo", err);
      setErrors([...errors, "Error fetching todo"]);
    }
  }

  async function updateTodoClimates(todoId, climateIds, todoSeason) {
    console.log("updateTodoClimates", todoId, climateIds, todoSeason);
    try {
      const oldTodoClimates = await fetchTodoClimates(todoId);
      const remove = oldTodoClimates.filter(
        (item) => !climateIds.includes(item.climateId)
      );
      console.log("remove", remove);

      const update = oldTodoClimates.filter((item) =>
        climateIds.includes(item.climateId)
      );
      console.log("update", update);

      const oldClimateIds = oldTodoClimates.map((item) => item.climateId);
      const add = climateIds.filter((item) => !oldClimateIds.includes(item));

      const addPromises = add.map((climateId) =>
        API.graphql(
          graphqlOperation(createTodoClimate, {
            input: { climateId, todoId, todoSeason },
          })
        )
      );

      const removePromises = remove.map((todoClimate) =>
        API.graphql(
          graphqlOperation(deleteTodoClimate, {
            input: { id: todoClimate.id },
          })
        )
      );

      const updatePromises = update.map((todoClimate) => {
        const cleanTodoClimate = omit(todoClimate, [
          "createdAt",
          "updatedAt",
          "todo",
        ]);
        return API.graphql(
          graphqlOperation(updateTodoClimate, {
            input: { ...cleanTodoClimate, todoSeason },
          })
        );
      });
      return [...addPromises, ...removePromises, ...updatePromises];
    } catch (err) {
      console.log(`error updating todoclimate: ${todoId}, ${climateIds}`, err);
      setErrors([...errors, `Error updating climates for Todo ${todoId}`]);
    }
  }

  function toggleClimateIds(selected) {
    console.log("toggleClimateIds", selected);
    if (selected === undefined) {
      return;
    }
    const newClimateIds = selected.map((item) => item.id);
    setInput("climateIds", newClimateIds);
  }

  async function fetchClimates() {
    try {
      const categoryData = await API.graphql(graphqlOperation(listClimates));
      const list = categoryData.data.listClimates.items;

      // Sort categories alphabetically by id
      list.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      });

      setClimates(list);
    } catch (err) {
      console.log("error fetching climates", err);
      setErrors([...errors, "Error fetching climates"]);
    }
  }

  async function fetchCategories() {
    try {
      const categoryData = await API.graphql(graphqlOperation(listCategorys));
      const list = categoryData.data.listCategorys.items;
      console.log("fetchCategories", list);
      setCategories(list);
      setInput("categoryId", list[0].id);
    } catch (err) {
      console.log("error fetching categories", err);
      setErrors([...errors, "Error fetching categories"]);
    }
  }

  useEffect(() => {
    fetchCategories();
    fetchClimates();
    if (id !== "new") {
      fetchTodo(id);
      fetchImages(id);
    }
  }, []);

  async function addOrUpdateTodo() {
    try {
      if (!formState.title || !formState.categoryId || !formState.season) {
        setErrors([...errors, "Missing required value"]);
      }
      const todo = { ...formState };
      if (todo.id !== "") {
        // Create/update Todo
        const cleanTodo = omit(todo, [
          "climateIds",
          "climates",
          "createdAt",
          "updatedAt",
          "category",
          "images",
        ]);
        const todoPromise = await API.graphql(
          graphqlOperation(updateTodo, {
            input: cleanTodo,
          })
        );

        // Create/update links to climates
        const todoClimatePromises = updateTodoClimates(
          todo.id,
          todo.climateIds,
          todo.season
        );

        const allResults = await Promise.all([
          todoPromise,
          todoClimatePromises,
        ]);
        const results = allResults[0];
      } else {
        const newTodo = omit(todo, ["id", "climateIds", "category"]);
        const todoResult = await API.graphql(
          graphqlOperation(createTodo, {
            input: newTodo,
          })
        );

        // Create/update links to climates
        const todoClimateResult = await updateTodoClimates(
          todoResult.data.createTodo.id,
          todo.climateIds,
          todo.season
        );

        const results = todoResult;
      }
      console.log("todo updated or created successfully");
      navigate("/admin/todos");
    } catch (err) {
      console.log("error creating or updating todo:", err);
      setErrors([...errors, "Error creating or updating todo"]);
    }
  }

  const imagePlaceholderHandler = (enablePlaceholder, newImage = undefined) => {
    console.log("In imagePlaceholderHandler");
    if (enablePlaceholder) {
      setImages([
        ...images,
        { ...placeholderImage, placeholder: true, key: "placeholder" },
      ]);

      // Also start listening to new images added to the system
      onCreateImageSubscription();
    } else {
      const index = images.findIndex((item) => item.placeholder);
      var newImages = images;
      if (index !== -1) {
        newImages = [
          ...images.slice(0, index),
          ...images.slice(index + 1),
        ].sort(compareFunc);
      }
      if (newImage) {
        newImages.push(newImage);
      }
      setImages(newImages);
    }
  };

  const removeImage = `
    mutation RemoveImage($imgID: ID) {
      deleteTodoImage(input: {id: $imgID}) {
        id
      }
    }
  `;

  async function deleteImg(id) {
    console.log("id: ", id);
    const user = await Auth.currentAuthenticatedUser();
    console.log("user: ", user);
    const work = await API.graphql({
      ...graphqlOperation(removeImage, { imgID: id }),
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    console.log("response: ", work);
    return work;
  }

  const onRemoveHandler = async (imgId) => {
    const confirmation = await deleteImg(imgId);

    setImages(images.filter((item) => item.id !== imgId));
    return confirmation;
  };

  async function onCreateImageSubscription() {
    console.log("In onCreateImageSubscription()");
    const subscription = API.graphql(
      graphqlOperation(onCreateTodoImage, { todoId: id })
    ).subscribe({
      next: ({ provider, value }) => {
        console.log("in subscription with ", provider, value);
        imagePlaceholderHandler(false, value?.data?.onTodoCreatedTodoImage);
        if (value?.data?.onTodoCreatedTodoImage) {
          console.log("New image ready", { provider, value });
        } else {
          console.log("No new image?");
        }
      },
    });

    if (oldSubscription?.unsubscribe) {
      oldSubscription.unsubscribe();
    }

    setOldSubscription(subscription);
  }

  const CreateOrSaveIcon = formState.id === "" ? CreateIcon : SaveIcon;
  const createOrSaveText = formState.id === "" ? "Create" : "Save";
  const newWidth = "450";
  const DisplayImageUpload =
    formState.id === "" ? null : (
      <ButtonGroup>
        <S3ImageUpload
          id={{ id: formState.id, idName: "todoId" }}
          uploadPlaceholderHandler={imagePlaceholderHandler}
          resizeWidth={newWidth}
        />
      </ButtonGroup>
    );

  const seasons = ["Spring", "Summer", "Fall", "Winter"];

  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h2">Todo - {formState.title}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Link to="/admin/todos">
            <Typography>Back to list</Typography>
          </Link>
          <ButtonGroup>
            <Button onClick={addOrUpdateTodo} startIcon={<CreateOrSaveIcon />}>
              {createOrSaveText}
            </Button>
            <Button
              component={Link}
              startIcon={<CancelIcon />}
              to="/admin/todos"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid item>
          <FormControl required variant="filled">
            <InputLabel id="season-label">Season</InputLabel>
            <Select
              required
              labelId="season-label"
              value={formState.season}
              onChange={(event) => setInput("season", event.target.value)}
              className={classes.select}
            >
              {seasons.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl required variant="filled">
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              required
              labelId="category-label"
              value={formState.categoryId}
              onChange={(event) => setInput("categoryId", event.target.value)}
              className={classes.select}
            >
              {categories.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <TextField
            label="Subcontractor"
            variant="filled"
            onChange={(event) => setInput("subcontractor", event.target.value)}
            className={classes.input}
            value={formState.subcontractor}
            placeholder="Subcontractor"
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={climates}
            disableCloseOnSelect
            getOptionLabel={(option) =>
              `${option?.id} - ${option?.description}`
            }
            getOptionSelected={(option, value) => option?.id === value?.id}
            value={formState.climateIds.map((item) =>
              climates.find((climate) => climate.id === item)
            )}
            onChange={(event, newValue) => toggleClimateIds(newValue)}
            inputValue={climateInput}
            onInputChange={(event, newInputValue) =>
              setClimateInput(newInputValue)
            }
            className={classes.climate}
            renderInput={(params) => (
              <TextField {...params} label="Climates" variant="filled" />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Title"
            required
            variant="filled"
            onChange={(event) => setInput("title", event.target.value)}
            className={classes.input}
            value={formState.title}
            placeholder="Title"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Description"
            variant="filled"
            onChange={(event) => setInput("description", event.target.value)}
            className={classes.input}
            value={formState.description}
            placeholder="Description"
            multiline
            rows={4}
          />
        </Grid>

        <Grid item xs={12}>
          {DisplayImageUpload}
        </Grid>
        <Grid item xs={12}>
          <ImagesList images={images} onRemove={onRemoveHandler} />
        </Grid>

        <Grid item xs={12}>
          <ButtonGroup>
            <Button onClick={addOrUpdateTodo} startIcon={<CreateOrSaveIcon />}>
              {createOrSaveText}
            </Button>
            <Button
              component={Link}
              startIcon={<CancelIcon />}
              to="/admin/todos"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </form>
  );
};

export default App;
