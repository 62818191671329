/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCity = /* GraphQL */ `
  mutation CreateCity(
    $input: CreateCityInput!
    $condition: ModelCityConditionInput
  ) {
    createCity(input: $input, condition: $condition) {
      id
      name
      county
      type
      population
      url
      createdAt
      updatedAt
      state {
        id
        name
        createdAt
        updatedAt
      }
      zipcode {
        nextToken
      }
    }
  }
`;
export const updateCity = /* GraphQL */ `
  mutation UpdateCity(
    $input: UpdateCityInput!
    $condition: ModelCityConditionInput
  ) {
    updateCity(input: $input, condition: $condition) {
      id
      name
      county
      type
      population
      url
      createdAt
      updatedAt
      state {
        id
        name
        createdAt
        updatedAt
      }
      zipcode {
        nextToken
      }
    }
  }
`;
export const deleteCity = /* GraphQL */ `
  mutation DeleteCity(
    $input: DeleteCityInput!
    $condition: ModelCityConditionInput
  ) {
    deleteCity(input: $input, condition: $condition) {
      id
      name
      county
      type
      population
      url
      createdAt
      updatedAt
      state {
        id
        name
        createdAt
        updatedAt
      }
      zipcode {
        nextToken
      }
    }
  }
`;
export const createState = /* GraphQL */ `
  mutation CreateState(
    $input: CreateStateInput!
    $condition: ModelStateConditionInput
  ) {
    createState(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      ads {
        nextToken
      }
    }
  }
`;
export const updateState = /* GraphQL */ `
  mutation UpdateState(
    $input: UpdateStateInput!
    $condition: ModelStateConditionInput
  ) {
    updateState(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      ads {
        nextToken
      }
    }
  }
`;
export const deleteState = /* GraphQL */ `
  mutation DeleteState(
    $input: DeleteStateInput!
    $condition: ModelStateConditionInput
  ) {
    deleteState(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      ads {
        nextToken
      }
    }
  }
`;
export const createZipcode = /* GraphQL */ `
  mutation CreateZipcode(
    $input: CreateZipcodeInput!
    $condition: ModelZipcodeConditionInput
  ) {
    createZipcode(input: $input, condition: $condition) {
      zipcode
      zipcodeCityId
      latitude
      longitude
      createdAt
      updatedAt
      city {
        id
        name
        county
        type
        population
        url
        createdAt
        updatedAt
      }
      state {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateZipcode = /* GraphQL */ `
  mutation UpdateZipcode(
    $input: UpdateZipcodeInput!
    $condition: ModelZipcodeConditionInput
  ) {
    updateZipcode(input: $input, condition: $condition) {
      zipcode
      zipcodeCityId
      latitude
      longitude
      createdAt
      updatedAt
      city {
        id
        name
        county
        type
        population
        url
        createdAt
        updatedAt
      }
      state {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteZipcode = /* GraphQL */ `
  mutation DeleteZipcode(
    $input: DeleteZipcodeInput!
    $condition: ModelZipcodeConditionInput
  ) {
    deleteZipcode(input: $input, condition: $condition) {
      zipcode
      zipcodeCityId
      latitude
      longitude
      createdAt
      updatedAt
      city {
        id
        name
        county
        type
        population
        url
        createdAt
        updatedAt
      }
      state {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const createClimate = /* GraphQL */ `
  mutation CreateClimate(
    $input: CreateClimateInput!
    $condition: ModelClimateConditionInput
  ) {
    createClimate(input: $input, condition: $condition) {
      id
      description
      createdAt
      updatedAt
      todos {
        nextToken
      }
    }
  }
`;
export const updateClimate = /* GraphQL */ `
  mutation UpdateClimate(
    $input: UpdateClimateInput!
    $condition: ModelClimateConditionInput
  ) {
    updateClimate(input: $input, condition: $condition) {
      id
      description
      createdAt
      updatedAt
      todos {
        nextToken
      }
    }
  }
`;
export const deleteClimate = /* GraphQL */ `
  mutation DeleteClimate(
    $input: DeleteClimateInput!
    $condition: ModelClimateConditionInput
  ) {
    deleteClimate(input: $input, condition: $condition) {
      id
      description
      createdAt
      updatedAt
      todos {
        nextToken
      }
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createTodoImage = /* GraphQL */ `
  mutation CreateTodoImage(
    $input: CreateTodoImageInput!
    $condition: ModelTodoImageConditionInput
  ) {
    createTodoImage(input: $input, condition: $condition) {
      id
      todoId
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      owner
      createdAt
      updatedAt
      todo {
        id
        categoryId
        season
        title
        description
        subcontractor
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTodoImage = /* GraphQL */ `
  mutation UpdateTodoImage(
    $input: UpdateTodoImageInput!
    $condition: ModelTodoImageConditionInput
  ) {
    updateTodoImage(input: $input, condition: $condition) {
      id
      todoId
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      owner
      createdAt
      updatedAt
      todo {
        id
        categoryId
        season
        title
        description
        subcontractor
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTodoImage = /* GraphQL */ `
  mutation DeleteTodoImage(
    $input: DeleteTodoImageInput!
    $condition: ModelTodoImageConditionInput
  ) {
    deleteTodoImage(input: $input, condition: $condition) {
      id
      todoId
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      owner
      createdAt
      updatedAt
      todo {
        id
        categoryId
        season
        title
        description
        subcontractor
        createdAt
        updatedAt
      }
    }
  }
`;
export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      categoryId
      season
      title
      description
      subcontractor
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
      }
      images {
        nextToken
      }
      climates {
        nextToken
      }
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      categoryId
      season
      title
      description
      subcontractor
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
      }
      images {
        nextToken
      }
      climates {
        nextToken
      }
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      categoryId
      season
      title
      description
      subcontractor
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
      }
      images {
        nextToken
      }
      climates {
        nextToken
      }
    }
  }
`;
export const createTodoClimate = /* GraphQL */ `
  mutation CreateTodoClimate(
    $input: CreateTodoClimateInput!
    $condition: ModelTodoClimateConditionInput
  ) {
    createTodoClimate(input: $input, condition: $condition) {
      id
      climateId
      todoId
      todoSeason
      createdAt
      updatedAt
      climate {
        id
        description
        createdAt
        updatedAt
      }
      todo {
        id
        categoryId
        season
        title
        description
        subcontractor
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTodoClimate = /* GraphQL */ `
  mutation UpdateTodoClimate(
    $input: UpdateTodoClimateInput!
    $condition: ModelTodoClimateConditionInput
  ) {
    updateTodoClimate(input: $input, condition: $condition) {
      id
      climateId
      todoId
      todoSeason
      createdAt
      updatedAt
      climate {
        id
        description
        createdAt
        updatedAt
      }
      todo {
        id
        categoryId
        season
        title
        description
        subcontractor
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTodoClimate = /* GraphQL */ `
  mutation DeleteTodoClimate(
    $input: DeleteTodoClimateInput!
    $condition: ModelTodoClimateConditionInput
  ) {
    deleteTodoClimate(input: $input, condition: $condition) {
      id
      climateId
      todoId
      todoSeason
      createdAt
      updatedAt
      climate {
        id
        description
        createdAt
        updatedAt
      }
      todo {
        id
        categoryId
        season
        title
        description
        subcontractor
        createdAt
        updatedAt
      }
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      question
      answers {
        answer
        correct
        answerExp
      }
      season
      createdAt
      updatedAt
      image {
        nextToken
      }
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      question
      answers {
        answer
        correct
        answerExp
      }
      season
      createdAt
      updatedAt
      image {
        nextToken
      }
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      question
      answers {
        answer
        correct
        answerExp
      }
      season
      createdAt
      updatedAt
      image {
        nextToken
      }
    }
  }
`;
export const createQuizImage = /* GraphQL */ `
  mutation CreateQuizImage(
    $input: CreateQuizImageInput!
    $condition: ModelQuizImageConditionInput
  ) {
    createQuizImage(input: $input, condition: $condition) {
      id
      questionId
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      owner
      createdAt
      updatedAt
      question {
        id
        question
        season
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateQuizImage = /* GraphQL */ `
  mutation UpdateQuizImage(
    $input: UpdateQuizImageInput!
    $condition: ModelQuizImageConditionInput
  ) {
    updateQuizImage(input: $input, condition: $condition) {
      id
      questionId
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      owner
      createdAt
      updatedAt
      question {
        id
        question
        season
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteQuizImage = /* GraphQL */ `
  mutation DeleteQuizImage(
    $input: DeleteQuizImageInput!
    $condition: ModelQuizImageConditionInput
  ) {
    deleteQuizImage(input: $input, condition: $condition) {
      id
      questionId
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      owner
      createdAt
      updatedAt
      question {
        id
        question
        season
        createdAt
        updatedAt
      }
    }
  }
`;
export const createGradeScale = /* GraphQL */ `
  mutation CreateGradeScale(
    $input: CreateGradeScaleInput!
    $condition: ModelGradeScaleConditionInput
  ) {
    createGradeScale(input: $input, condition: $condition) {
      id
      explanation
      createdAt
      updatedAt
    }
  }
`;
export const updateGradeScale = /* GraphQL */ `
  mutation UpdateGradeScale(
    $input: UpdateGradeScaleInput!
    $condition: ModelGradeScaleConditionInput
  ) {
    updateGradeScale(input: $input, condition: $condition) {
      id
      explanation
      createdAt
      updatedAt
    }
  }
`;
export const deleteGradeScale = /* GraphQL */ `
  mutation DeleteGradeScale(
    $input: DeleteGradeScaleInput!
    $condition: ModelGradeScaleConditionInput
  ) {
    deleteGradeScale(input: $input, condition: $condition) {
      id
      explanation
      createdAt
      updatedAt
    }
  }
`;
export const createMissingZip = /* GraphQL */ `
  mutation CreateMissingZip(
    $input: CreateMissingZipInput!
    $condition: ModelMissingZipConditionInput
  ) {
    createMissingZip(input: $input, condition: $condition) {
      id
      count
      createdAt
      updatedAt
    }
  }
`;
export const updateMissingZip = /* GraphQL */ `
  mutation UpdateMissingZip(
    $input: UpdateMissingZipInput!
    $condition: ModelMissingZipConditionInput
  ) {
    updateMissingZip(input: $input, condition: $condition) {
      id
      count
      createdAt
      updatedAt
    }
  }
`;
export const deleteMissingZip = /* GraphQL */ `
  mutation DeleteMissingZip(
    $input: DeleteMissingZipInput!
    $condition: ModelMissingZipConditionInput
  ) {
    deleteMissingZip(input: $input, condition: $condition) {
      id
      count
      createdAt
      updatedAt
    }
  }
`;
export const createAd = /* GraphQL */ `
  mutation CreateAd($input: CreateAdInput!, $condition: ModelAdConditionInput) {
    createAd(input: $input, condition: $condition) {
      id
      title
      description
      url
      createdAt
      updatedAt
      image {
        nextToken
      }
      state {
        nextToken
      }
    }
  }
`;
export const updateAd = /* GraphQL */ `
  mutation UpdateAd($input: UpdateAdInput!, $condition: ModelAdConditionInput) {
    updateAd(input: $input, condition: $condition) {
      id
      title
      description
      url
      createdAt
      updatedAt
      image {
        nextToken
      }
      state {
        nextToken
      }
    }
  }
`;
export const deleteAd = /* GraphQL */ `
  mutation DeleteAd($input: DeleteAdInput!, $condition: ModelAdConditionInput) {
    deleteAd(input: $input, condition: $condition) {
      id
      title
      description
      url
      createdAt
      updatedAt
      image {
        nextToken
      }
      state {
        nextToken
      }
    }
  }
`;
export const createAdImage = /* GraphQL */ `
  mutation CreateAdImage(
    $input: CreateAdImageInput!
    $condition: ModelAdImageConditionInput
  ) {
    createAdImage(input: $input, condition: $condition) {
      id
      adId
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      owner
      createdAt
      updatedAt
      ad {
        id
        title
        description
        url
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateAdImage = /* GraphQL */ `
  mutation UpdateAdImage(
    $input: UpdateAdImageInput!
    $condition: ModelAdImageConditionInput
  ) {
    updateAdImage(input: $input, condition: $condition) {
      id
      adId
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      owner
      createdAt
      updatedAt
      ad {
        id
        title
        description
        url
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteAdImage = /* GraphQL */ `
  mutation DeleteAdImage(
    $input: DeleteAdImageInput!
    $condition: ModelAdImageConditionInput
  ) {
    deleteAdImage(input: $input, condition: $condition) {
      id
      adId
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      owner
      createdAt
      updatedAt
      ad {
        id
        title
        description
        url
        createdAt
        updatedAt
      }
    }
  }
`;
export const createAdStateLink = /* GraphQL */ `
  mutation CreateAdStateLink(
    $input: CreateAdStateLinkInput!
    $condition: ModelAdStateLinkConditionInput
  ) {
    createAdStateLink(input: $input, condition: $condition) {
      id
      adId
      stateId
      createdAt
      updatedAt
      state {
        id
        name
        createdAt
        updatedAt
      }
      ad {
        id
        title
        description
        url
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateAdStateLink = /* GraphQL */ `
  mutation UpdateAdStateLink(
    $input: UpdateAdStateLinkInput!
    $condition: ModelAdStateLinkConditionInput
  ) {
    updateAdStateLink(input: $input, condition: $condition) {
      id
      adId
      stateId
      createdAt
      updatedAt
      state {
        id
        name
        createdAt
        updatedAt
      }
      ad {
        id
        title
        description
        url
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteAdStateLink = /* GraphQL */ `
  mutation DeleteAdStateLink(
    $input: DeleteAdStateLinkInput!
    $condition: ModelAdStateLinkConditionInput
  ) {
    deleteAdStateLink(input: $input, condition: $condition) {
      id
      adId
      stateId
      createdAt
      updatedAt
      state {
        id
        name
        createdAt
        updatedAt
      }
      ad {
        id
        title
        description
        url
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSeasons = /* GraphQL */ `
  mutation CreateSeasons(
    $input: CreateSeasonsInput!
    $condition: ModelSeasonsConditionInput
  ) {
    createSeasons(input: $input, condition: $condition) {
      id
      spring {
        month
        day
      }
      summer {
        month
        day
      }
      fall {
        month
        day
      }
      winter {
        month
        day
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSeasons = /* GraphQL */ `
  mutation UpdateSeasons(
    $input: UpdateSeasonsInput!
    $condition: ModelSeasonsConditionInput
  ) {
    updateSeasons(input: $input, condition: $condition) {
      id
      spring {
        month
        day
      }
      summer {
        month
        day
      }
      fall {
        month
        day
      }
      winter {
        month
        day
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSeasons = /* GraphQL */ `
  mutation DeleteSeasons(
    $input: DeleteSeasonsInput!
    $condition: ModelSeasonsConditionInput
  ) {
    deleteSeasons(input: $input, condition: $condition) {
      id
      spring {
        month
        day
      }
      summer {
        month
        day
      }
      fall {
        month
        day
      }
      winter {
        month
        day
      }
      createdAt
      updatedAt
    }
  }
`;
