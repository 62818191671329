import React, { useState, useEffect } from "react";
import { Link } from "gatsby-theme-material-ui";
import { Button, withStyles } from "@material-ui/core";
import { navigate } from "@reach/router";

import { Auth } from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { setUser, isLoggedIn } from "../../utils/auth";
import Error from "../Error";

const styles = (theme) => ({
  input: {
    height: 40,
    margin: "10px 0px",
    padding: 7,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    fontSize: "1.2em",
    padding: "15px 7px",
    cursor: "pointer",
    textAlign: "center",
    marginBottom: 10,
  },
});

const Login = (props) => {
  const setLogin = async () => {
    console.log("in setLogin");
    const user = await Auth.currentAuthenticatedUser();
    const userInfo = {
      ...user.attributes,
      username: user.username,
    };
    setUser(userInfo);
    navigate("/admin");
  };
  useEffect(() => {
    setLogin();
  }, []);

  onAuthUIStateChange((nextAuthState, authData) => {
    if (nextAuthState === AuthState.SignedIn) {
      setLogin();
      navigate("/admin");
    }
  });

  if (isLoggedIn()) navigate("/admin");
  const { classes } = props;
  return (
    <AmplifyAuthenticator>
      <AmplifySignIn slot="sign-in" hideSignUp />
    </AmplifyAuthenticator>
  );
};

export default withStyles(styles, { withTheme: true })(Login);
