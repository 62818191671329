import React, { useEffect, useState } from "react";
import { Link } from "gatsby-theme-material-ui";
import { navigate } from "@reach/router";
import {
  makeStyles,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  AddBox as CreateIcon,
  Cancel as CancelIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import { createCategory, updateCategory } from "../../../graphql/mutations";
import { getCategory } from "../../../graphql/queries";

const initialState = { name: "", id: "" };

const useStyles = makeStyles((theme) => ({
  container: {},
  input: {
    width: "100%",
  },
}));

const App = ({ id }) => {
  const classes = useStyles();
  const [formState, setFormState] = useState(initialState);

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  async function fetchCategory(inputId) {
    try {
      const categoryData = await API.graphql(
        graphqlOperation(getCategory, { id: inputId })
      );
      const category = categoryData.data.getCategory;
      setFormState(category);
    } catch (err) {
      console.log("error fetching category", err);
      setFormState(initialState);
    }
  }

  useEffect(() => {
    if (id !== "new") {
      fetchCategory(id);
    }
  }, []);

  async function addOrUpdateCategory() {
    try {
      if (!formState.name) return;
      const category = { ...formState };
      let result;
      if (category.id !== "") {
        const results = await API.graphql(
          graphqlOperation(updateCategory, {
            input: {
              id: category.id,
              name: category.name,
            },
          })
        );
        result = results.data.updateCategory;
      } else {
        const results = await API.graphql(
          graphqlOperation(createCategory, {
            input: { name: category.name },
          })
        );
        result = results.data.createCategory;
      }
      setFormState(result);
      navigate("/admin/categories");
    } catch (err) {
      console.log("error creating or updating category:", err);
    }
  }

  const CreateOrSaveIcon = formState.id === "" ? CreateIcon : SaveIcon;
  const createOrSaveText = formState.id === "" ? "Create" : "Save";

  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={2} className={classes.container}>
        <Grid xs={12}>
          <Typography variant="h2">Category - {formState.name}</Typography>
        </Grid>
        <Grid xs={12}>
          <Link to="/admin/categories">
            <Typography>Back to list</Typography>
          </Link>
        </Grid>
        <Grid xs={12}>
          <TextField
            required
            variant="filled"
            label="Name"
            onChange={(event) => setInput("name", event.target.value)}
            className={classes.input}
            value={formState.name}
            placeholder="Name"
          />
        </Grid>
        <Grid>
          <ButtonGroup>
            <Button
              className={classes.button}
              onClick={addOrUpdateCategory}
              startIcon={<CreateOrSaveIcon />}
            >
              {createOrSaveText}
            </Button>
            <Button
              component={Link}
              startIcon={<CancelIcon />}
              to="/admin/categories"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </form>
  );
};

export default App;
