import React, { useEffect, useState } from "react";
import { Link } from "gatsby-theme-material-ui";
import { navigate } from "@reach/router";
import {
  makeStyles,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  Grid,
  MenuItem,
  IconButton,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  AddBox as CreateIcon,
  Cancel as CancelIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import { createGradeScale, updateGradeScale } from "../../../graphql/mutations";
import { getGradeScale } from "../../../graphql/queries";

const initialState = {
  id: "",
  explanation1: "",
  explanation2: "",
  explanation3: "",
};

const useStyles = makeStyles((theme) => ({
  container: {},
  input: {
    width: "100%",
  },
}));

const App = ({ id }) => {
  const classes = useStyles();
  const [formState, setFormState] = useState(initialState);

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  async function fetchGradeScale(inputId) {
    try {
      const gradeScaleData = await API.graphql(
        graphqlOperation(getGradeScale, { id: inputId })
      );
      const gradeScale = gradeScaleData.data.getGradeScale;
      setFormState({
        id: gradeScale.id,
        explanation1: gradeScale.explanation[0],
        explanation2: gradeScale.explanation[1],
        explanation3: gradeScale.explanation[2],
      });
    } catch (err) {
      console.log("error fetching gradeScale", err);
      setFormState(initialState);
    }
  }

  useEffect(() => {
    if (id !== "new") {
      fetchGradeScale(id);
    }
  }, []);

  async function addOrUpdateGradeScale() {
    try {
      if (!formState.id) return;
      const gradeScale = { ...formState };
      let result;
      if (id !== "new") {
        const results = await API.graphql(
          graphqlOperation(updateGradeScale, {
            input: {
              id: gradeScale.id,
              explanation: [
                gradeScale.explanation1,
                gradeScale.explanation2,
                gradeScale.explanation3,
              ],
            },
          })
        );
        result = results.data.updateGradeScale;
      } else {
        const results = await API.graphql(
          graphqlOperation(createGradeScale, {
            input: {
              id: gradeScale.id,
              explanation: [
                gradeScale.explanation1,
                gradeScale.explanation2,
                gradeScale.explanation3,
              ],
            },
          })
        );
        result = results.data.createGradeScale;
      }
      setFormState(result);
      navigate("/admin/explanations");
    } catch (err) {
      console.log("error creating or updating gradeScale:", err);
    }
  }

  const CreateOrSaveIcon = formState.id === "" ? CreateIcon : SaveIcon;
  const createOrSaveText = formState.id === "" ? "Create" : "Save";

  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h2">GradeScale - {formState.id}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Link to="/admin/explanations">
            <Typography>Back to list</Typography>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <FormControl required variant="filled">
            <InputLabel id="id">ID</InputLabel>
            <Select
              required
              labelId="id"
              value={formState.id}
              onChange={(event) => setInput("id", event.target.value)}
              className={classes.select}
            >
              <MenuItem key="A" value={"A"}>
                A
              </MenuItem>
              <MenuItem key="B" value={"B"}>
                B
              </MenuItem>
              <MenuItem key="C" value={"C"}>
                C
              </MenuItem>
              <MenuItem key="F" value={"F"}>
                F
              </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            variant="filled"
            label="First Option"
            onChange={(event) => setInput("explanation1", event.target.value)}
            className={classes.input}
            value={formState.explanation1}
            placeholder="explanation1"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            variant="filled"
            label="Second Option"
            onChange={(event) => setInput("explanation2", event.target.value)}
            className={classes.input}
            value={formState.explanation2}
            placeholder="explanation2"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            variant="filled"
            label="Third Option"
            onChange={(event) => setInput("explanation3", event.target.value)}
            className={classes.input}
            value={formState.explanation3}
            placeholder="explanation3"
          />
        </Grid>
        <Grid>
          <ButtonGroup>
            <Button
              className={classes.button}
              onClick={addOrUpdateGradeScale}
              startIcon={<CreateOrSaveIcon />}
            >
              {createOrSaveText}
            </Button>
            <Button
              component={Link}
              startIcon={<CancelIcon />}
              to="/admin/explanations"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </form>
  );
};

export default App;
