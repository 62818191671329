import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import {
  makeStyles,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  AddBox as AddBoxIcon,
  Edit as EditIcon,
  DeleteForever as DeleteIcon,
} from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import { Link } from "gatsby-theme-material-ui";
import { deleteMissingZip } from "../../../graphql/mutations";
import { listMissingZips } from "../../../graphql/queries";

const useStyles = makeStyles((theme) => ({
  container: {},
  addNewContainer: {
    textAlign: "right",
  },
  addNew: {
    marginTop: "1.5rem",
  },
  table: {
    minWidth: 300,
  },
  edit: {},
  remove: {
    backgroundColor: theme.palette.error.dark,
  },
}));

const App = () => {
  const classes = useStyles();
  const [missingZips, setMissingZips] = useState([]);
  const [errors, setErrors] = useState([]);

  async function fetchMissingZips() {
    try {
      const missingZipData = await API.graphql(
        graphqlOperation(listMissingZips)
      );
      const fetchedMissingZips = missingZipData.data.listMissingZips.items.sort(
        sortFunction
      );
      console.log(fetchedMissingZips);
      setMissingZips(fetchedMissingZips);
    } catch (err) {
      console.log("error fetching missingZips");
      setErrors([...errors, "Error fetching missingZips"]);
    }
  }

  useEffect(() => {
    fetchMissingZips();
  }, []);

  async function removeMissingZip(id) {
    if (typeof id === "undefined") {
      return;
    }
    try {
      const results = await API.graphql(
        graphqlOperation(deleteMissingZip, { input: { id } })
      );
      console.log("removeMissingZip", results);

      const newMissingZips = missingZips.filter((item) => item.id !== id);
      setMissingZips(newMissingZips);
    } catch (err) {
      console.log("error removing missingZip:", err);
      setErrors([...errors, "Error removing missingZip"]);
    }
  }

  const sortFunction = (a, b) => {
    return b.count - a.count;
  };

  return (
    <Grid container justify="space-between" className={classes.container}>
      <Grid item xs={6}>
        <Typography variant="h2">MissingZips</Typography>
      </Grid>
      <Grid item xs={4} className={classes.addNewContainer}>
        <Button
          className={classes.addNew}
          variant="contained"
          startIcon={<AddBoxIcon />}
          component={Link}
          to="/admin/missingzips/new"
        >
          Add new item
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="list of missingZips">
            <TableBody>
              {missingZips.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Typography>Zipcode: {item.id}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Number of hits: {item.count}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <ButtonGroup>
                      <IconButton
                        className={classes.remove}
                        onClick={() => {
                          removeMissingZip(item.id);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default App;
