import React, { useEffect, useState } from "react";
import { omit } from "lodash";
import Amplify, { Auth, API, graphqlOperation, S3Image } from "aws-amplify";
import { Link } from "gatsby-theme-material-ui";
import { useStaticQuery, graphql } from "gatsby";
import { navigate } from "@reach/router";
import {
  makeStyles,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  InputLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  AddBox as CreateIcon,
  Cancel as CancelIcon,
  CheckBoxOutlineBlank as CheckboxBlankIcon,
  CheckBox as CheckboxIcon,
  Refresh as RefreshIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

const getSeasons = /* GraphQL */ `
  query GetOneSeasons($id: ID!) {
    getSeasons(id: $id) {
      id
      spring {
        month
        day
      }
      summer {
        month
        day
      }
      fall {
        month
        day
      }
      winter {
        month
        day
      }
    }
  }
`;

const createSeasons = /* GraphQL */ `
  mutation CreateOneSeasons(
    $input: CreateSeasonsInput!
    $condition: ModelSeasonsConditionInput
  ) {
    createSeasons(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      spring {
        month
        day
      }
      summer {
        month
        day
      }
      fall {
        month
        day
      }
      winter {
        month
        day
      }
    }
  }
`;

const updateSeasons = /* GraphQL */ `
  mutation UpdateOneSeasons(
    $input: UpdateSeasonsInput!
    $condition: ModelSeasonsConditionInput
  ) {
    updateSeasons(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      spring {
        month
        day
      }
      summer {
        month
        day
      }
      fall {
        month
        day
      }
      winter {
        month
        day
      }
    }
  }
`;

const initialState = {
  id: "",
  springMonth: "",
  summerMonth: "",
  fallMonth: "",
  winterMonth: "",
  springDay: "",
  summerDay: "",
  fallDay: "",
  winterDay: "",
};

const useStyles = makeStyles((theme) => ({
  select: {
    width: 250,
  },
  input: {
    width: "100%",
  },
}));

const App = ({ id }) => {
  const classes = useStyles();
  const [formState, setFormState] = useState(initialState);
  const [errors, setErrors] = useState([]);
  const isNewSeasons = id === "new";
  const CreateOrSaveIcon = isNewSeasons ? CreateIcon : SaveIcon;
  const createOrSaveText = isNewSeasons ? "Create" : "Save";

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  async function fetchSeasons(inputId) {
    try {
      const seasonsCall = await API.graphql(
        graphqlOperation(getSeasons, { id: inputId })
      );
      const seasons = seasonsCall.data.getSeasons;
      console.log("fetchSeasons", seasons);
      setFormState({
        id: seasons.id,
        springMonth: seasons.spring.month,
        summerMonth: seasons.summer.month,
        fallMonth: seasons.fall.month,
        winterMonth: seasons.winter.month,
        springDay: seasons.spring.day,
        summerDay: seasons.summer.day,
        fallDay: seasons.fall.day,
        winterDay: seasons.winter.day,
      });
      console.log("formState: ", formState);
    } catch (err) {
      console.log("error fetching seasons", err);
      setErrors([...errors, "Error fetching seasons"]);
    }
  }

  useEffect(() => {
    if (!isNewSeasons) {
      fetchSeasons(id);
    }
  }, []);

  const checkInput = () => {
    const keys = Object.keys(formState);
    const check = keys.map((key) => {
      if (key !== "id" && formState[key] === "") {
        alert("Error with form: missing " + key);
        setErrors([...errors, "Missing required value"]);
        throw errors;
      }
    });
  };

  const createNewSeasons = async (obj) => {
    const cleanSeasons = omit(obj, ["id"]);
    const seasonsResult = await API.graphql(
      graphqlOperation(createSeasons, {
        input: cleanSeasons,
      })
    );
  };

  const updateExistingSeasons = async (obj) => {
    const seasonsResult = await API.graphql(
      graphqlOperation(updateSeasons, {
        input: obj,
      })
    );
  };

  const addOrUpdateSeasons = async () => {
    try {
      checkInput();
      const newSeasons = {
        id: formState.id,
        spring: {
          month: formState.springMonth,
          day: formState.springDay,
        },
        summer: {
          month: formState.summerMonth,
          day: formState.summerDay,
        },
        fall: {
          month: formState.fallMonth,
          day: formState.fallDay,
        },
        winter: {
          month: formState.winterMonth,
          day: formState.winterDay,
        },
      };
      if (isNewSeasons) {
        await createNewSeasons(newSeasons);
      } else {
        await updateExistingSeasons(newSeasons);
      }
      console.log("seasons updated or created successfully");
      navigate("/admin/seasons");
    } catch (err) {
      console.log("error creating or updating seasons:", err);
      setErrors([...errors, "Error creating or updating seasons"]);
    }
  };

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2">Custom Seasons - {formState.id}</Typography>
        </Grid>

        <Grid item xs={12}>
          <ButtonGroup>
            <Button
              onClick={addOrUpdateSeasons}
              startIcon={<CreateOrSaveIcon />}
            >
              {createOrSaveText}
            </Button>
            <Button
              component={Link}
              startIcon={<CancelIcon />}
              to="/admin/seasons"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid item>
          <FormControl required variant="filled">
            <InputLabel id="Spring - Month">Spring - Month</InputLabel>
            <Select
              required
              labelId="Spring - Month"
              value={formState.springMonth}
              onChange={(event) => setInput("springMonth", event.target.value)}
              className={classes.select}
            >
              {months.map((item, ind) => (
                <MenuItem key={item} value={ind}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Spring - Day"
            required
            variant="filled"
            onChange={(event) => setInput("springDay", event.target.value)}
            className={classes.input}
            value={formState.springDay}
            placeholder="Spring - Day"
          />
        </Grid>

        <Grid item>
          <FormControl required variant="filled">
            <InputLabel id="Summer - Month">Summer - Month</InputLabel>
            <Select
              required
              labelId="Summer - Month"
              value={formState.summerMonth}
              onChange={(event) => setInput("summerMonth", event.target.value)}
              className={classes.select}
            >
              {months.map((item, ind) => (
                <MenuItem key={item} value={ind}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Summer - Day"
            required
            variant="filled"
            onChange={(event) => setInput("summerDay", event.target.value)}
            className={classes.input}
            value={formState.summerDay}
            placeholder="Summer - Day"
          />
        </Grid>

        <Grid item>
          <FormControl required variant="filled">
            <InputLabel id="Fall - Month">Fall - Month</InputLabel>
            <Select
              required
              labelId="Fall - Month"
              value={formState.fallMonth}
              onChange={(event) => setInput("fallMonth", event.target.value)}
              className={classes.select}
            >
              {months.map((item, ind) => (
                <MenuItem key={item} value={ind}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Fall - Day"
            required
            variant="filled"
            onChange={(event) => setInput("fallDay", event.target.value)}
            className={classes.input}
            value={formState.fallDay}
            placeholder="Fall - Day"
          />
        </Grid>

        <Grid item>
          <FormControl required variant="filled">
            <InputLabel id="Winter - Month">Winter - Month</InputLabel>
            <Select
              required
              labelId="Winter - Month"
              value={formState.winterMonth}
              onChange={(event) => setInput("winterMonth", event.target.value)}
              className={classes.select}
            >
              {months.map((item, ind) => (
                <MenuItem key={item} value={ind}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Winter - Day"
            required
            variant="filled"
            onChange={(event) => setInput("winterDay", event.target.value)}
            className={classes.input}
            value={formState.winterDay}
            placeholder="winter - Day"
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonGroup>
            <Button
              onClick={addOrUpdateSeasons}
              startIcon={<CreateOrSaveIcon />}
            >
              {createOrSaveText}
            </Button>
            <Button
              component={Link}
              startIcon={<CancelIcon />}
              to="/admin/seasons"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </form>
  );
};

export default App;
