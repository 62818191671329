/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listCitys = /* GraphQL */ `
  query ListCitys(
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        county
        type
        population
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCity = /* GraphQL */ `
  query GetCity($id: ID!) {
    getCity(id: $id) {
      id
      name
      county
      type
      population
      url
      createdAt
      updatedAt
      state {
        id
        name
        createdAt
        updatedAt
      }
      zipcode {
        nextToken
      }
    }
  }
`;
export const listStates = /* GraphQL */ `
  query ListStates(
    $filter: ModelStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getState = /* GraphQL */ `
  query GetState($id: ID!) {
    getState(id: $id) {
      id
      name
      createdAt
      updatedAt
      ads {
        nextToken
      }
    }
  }
`;
export const getZipcode = /* GraphQL */ `
  query GetZipcode($zipcode: String!) {
    getZipcode(zipcode: $zipcode) {
      zipcode
      zipcodeCityId
      latitude
      longitude
      createdAt
      updatedAt
      city {
        id
        name
        county
        type
        population
        url
        createdAt
        updatedAt
      }
      state {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const listZipcodes = /* GraphQL */ `
  query ListZipcodes(
    $zipcode: String
    $filter: ModelZipcodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZipcodes(
      zipcode: $zipcode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        zipcode
        zipcodeCityId
        latitude
        longitude
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listClimates = /* GraphQL */ `
  query ListClimates(
    $filter: ModelClimateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClimates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClimate = /* GraphQL */ `
  query GetClimate($id: ID!) {
    getClimate(id: $id) {
      id
      description
      createdAt
      updatedAt
      todos {
        nextToken
      }
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const getTodoImage = /* GraphQL */ `
  query GetTodoImage($id: ID!) {
    getTodoImage(id: $id) {
      id
      todoId
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      owner
      createdAt
      updatedAt
      todo {
        id
        categoryId
        season
        title
        description
        subcontractor
        createdAt
        updatedAt
      }
    }
  }
`;
export const listTodoImages = /* GraphQL */ `
  query ListTodoImages(
    $filter: ModelTodoImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodoImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        todoId
        bucket
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTodoImagesByTodo = /* GraphQL */ `
  query ListTodoImagesByTodo(
    $todoId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTodoImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodoImagesByTodo(
      todoId: $todoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        todoId
        bucket
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryId
        season
        title
        description
        subcontractor
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      categoryId
      season
      title
      description
      subcontractor
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
      }
      images {
        nextToken
      }
      climates {
        nextToken
      }
    }
  }
`;
export const getTodoClimate = /* GraphQL */ `
  query GetTodoClimate($id: ID!) {
    getTodoClimate(id: $id) {
      id
      climateId
      todoId
      todoSeason
      createdAt
      updatedAt
      climate {
        id
        description
        createdAt
        updatedAt
      }
      todo {
        id
        categoryId
        season
        title
        description
        subcontractor
        createdAt
        updatedAt
      }
    }
  }
`;
export const listTodoClimates = /* GraphQL */ `
  query ListTodoClimates(
    $filter: ModelTodoClimateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodoClimates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        climateId
        todoId
        todoSeason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const lookupClimateZone = /* GraphQL */ `
  query LookupClimateZone($input: LocationInput) {
    lookupClimateZone(input: $input) {
      climateCategory {
        id
        description
        createdAt
        updatedAt
      }
      climate
      error
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        season
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      question
      answers {
        answer
        correct
        answerExp
      }
      season
      createdAt
      updatedAt
      image {
        nextToken
      }
    }
  }
`;
export const getQuizImage = /* GraphQL */ `
  query GetQuizImage($id: ID!) {
    getQuizImage(id: $id) {
      id
      questionId
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      owner
      createdAt
      updatedAt
      question {
        id
        question
        season
        createdAt
        updatedAt
      }
    }
  }
`;
export const listQuizImages = /* GraphQL */ `
  query ListQuizImages(
    $filter: ModelQuizImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionId
        bucket
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQuizImagesByQuestion = /* GraphQL */ `
  query ListQuizImagesByQuestion(
    $questionId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuizImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizImagesByQuestion(
      questionId: $questionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionId
        bucket
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGradeScale = /* GraphQL */ `
  query GetGradeScale($id: ID!) {
    getGradeScale(id: $id) {
      id
      explanation
      createdAt
      updatedAt
    }
  }
`;
export const listGradeScales = /* GraphQL */ `
  query ListGradeScales(
    $filter: ModelGradeScaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGradeScales(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        explanation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMissingZip = /* GraphQL */ `
  query GetMissingZip($id: ID!) {
    getMissingZip(id: $id) {
      id
      count
      createdAt
      updatedAt
    }
  }
`;
export const listMissingZips = /* GraphQL */ `
  query ListMissingZips(
    $filter: ModelMissingZipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMissingZips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        count
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listZipcodesByCount = /* GraphQL */ `
  query ListZipcodesByCount(
    $count: Int
    $sortDirection: ModelSortDirection
    $filter: ModelMissingZipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZipcodesByCount(
      count: $count
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        count
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAds = /* GraphQL */ `
  query ListAds($filter: ModelAdFilterInput, $limit: Int, $nextToken: String) {
    listAds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAd = /* GraphQL */ `
  query GetAd($id: ID!) {
    getAd(id: $id) {
      id
      title
      description
      url
      createdAt
      updatedAt
      image {
        nextToken
      }
      state {
        nextToken
      }
    }
  }
`;
export const getAdImage = /* GraphQL */ `
  query GetAdImage($id: ID!) {
    getAdImage(id: $id) {
      id
      adId
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      owner
      createdAt
      updatedAt
      ad {
        id
        title
        description
        url
        createdAt
        updatedAt
      }
    }
  }
`;
export const listAdImages = /* GraphQL */ `
  query ListAdImages(
    $filter: ModelAdImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        adId
        bucket
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAdImagesByAd = /* GraphQL */ `
  query ListAdImagesByAd(
    $adId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAdImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdImagesByAd(
      adId: $adId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        adId
        bucket
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAdStateLink = /* GraphQL */ `
  query GetAdStateLink($id: ID!) {
    getAdStateLink(id: $id) {
      id
      adId
      stateId
      createdAt
      updatedAt
      state {
        id
        name
        createdAt
        updatedAt
      }
      ad {
        id
        title
        description
        url
        createdAt
        updatedAt
      }
    }
  }
`;
export const listAdStateLinks = /* GraphQL */ `
  query ListAdStateLinks(
    $filter: ModelAdStateLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdStateLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        adId
        stateId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSeasons = /* GraphQL */ `
  query GetSeasons($id: ID!) {
    getSeasons(id: $id) {
      id
      spring {
        month
        day
      }
      summer {
        month
        day
      }
      fall {
        month
        day
      }
      winter {
        month
        day
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSeasonss = /* GraphQL */ `
  query ListSeasonss(
    $filter: ModelSeasonsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSeasonss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
