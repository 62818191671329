import React, { useState } from "react";
import { S3Image } from "aws-amplify-react";
import Storage from "@aws-amplify/storage";
import { Button, Divider } from "@material-ui/core";
import { InsertPhotoOutlined as InsertPhotoIcon } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { Auth } from "aws-amplify";
import { v4 as uuid } from "uuid";
import Image from "gatsby-image";

export const S3ImageUpload = (props) => {
  const [uploading, setUploading] = useState(false);
  const typeId = props.id.id;
  const typeIdName = props.id.idName;
  const newWidth = props?.resizeWidth;
  console.log(typeIdName, typeId);

  const uploadFile = async (file) => {
    const fileName = `upload/${uuid()}`;
    const user = await Auth.currentAuthenticatedUser();
    console.log(user);

    // Note user-defined metadata keys will be cast to lowercase
    const result = await Storage.vault.put(fileName, file, {
      metadata: {
        [typeIdName]: typeId,
        metadata_resize_width: newWidth,
        owner: user.username,
      },
    });

    console.log("Uploaded file: ", result);
  };

  const onChange = async (e) => {
    setUploading(true);
    props.uploadPlaceholderHandler(true);

    const files = [];
    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files.item(i));
    }

    await Promise.all(files.map((f) => uploadFile(f)));

    setUploading(false);
  };

  return (
    <>
      <Button
        onClick={() => document.getElementById("add-image-file-input").click()}
        disabled={uploading}
        startIcon={<InsertPhotoIcon />}
      >
        {uploading ? "Uploading..." : "Add Images"}
      </Button>
      <input
        id="add-image-file-input"
        type="file"
        accept="image/*"
        multiple
        onChange={onChange}
        style={{ display: "none" }}
      />
    </>
  );
};

export const ImagesList = React.memo((props) => {
  const onRemove = (id) => props.onRemove(id);
  const ImageItems = (props) => {
    return (
      <>
        {props?.images?.map((image) => {
          const remover = async () => {
            console.log(image);
            return await onRemove(image.id);
          };
          if (image.thumbnail) {
            return (
              <>
                <S3Image
                  key={image.thumbnail.key}
                  imgKey={`resized/${image.thumbnail.key.replace(
                    /.+resized\//,
                    ""
                  )}`}
                  level="public"
                  style={{ display: "inline-block", paddingRight: "5px" }}
                />
                <Button onClick={remover}>
                  <CloseIcon />
                </Button>
              </>
            );
          } else {
            return (
              <Image
                key={image.key}
                fixed={image.fixed}
                style={{ display: "inline-block", paddingRight: "5px" }}
              />
            );
          }
        })}
      </>
    );
  };

  return (
    <div>
      <Divider hidden />
      <ImageItems images={props.images} />
    </div>
  );
});
