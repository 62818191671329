import React, { useEffect, useState } from "react";
import { omit } from "lodash";
import Amplify, { Auth, API, graphqlOperation, S3Image } from "aws-amplify";
import { Link } from "gatsby-theme-material-ui";
import { useStaticQuery, graphql } from "gatsby";
import { navigate } from "@reach/router";
import {
  makeStyles,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  InputLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  AddBox as CreateIcon,
  Cancel as CancelIcon,
  CheckBoxOutlineBlank as CheckboxBlankIcon,
  CheckBox as CheckboxIcon,
  Refresh as RefreshIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { v4 as uuid } from "uuid";
import {
  createCity,
  updateCity,
  updateZipcode,
} from "../../../graphql/mutations";
import { listStates } from "../../../graphql/queries";

const getCity = /* GraphQL */ `
  query CustomGetCity($id: ID!) {
    getCity(id: $id) {
      id
      name
      county
      type
      population
      url
      createdAt
      updatedAt
      state {
        id
        name
        createdAt
        updatedAt
      }
      zipcode {
        items {
          zipcode
        }
      }
    }
  }
`;
const initialState = {
  id: "",
  name: "",
  county: "",
  type: "City",
  population: "",
  url: "",
  state: {
    id: "",
  },
};

const useStyles = makeStyles((theme) => ({
  container: {},
  select: {
    width: 250,
  },
  input: {
    width: "100%",
  },
}));

const App = ({ id }) => {
  const classes = useStyles();
  const [formState, setFormState] = useState(initialState);
  const [errors, setErrors] = useState([]);
  const [states, setStates] = useState([{ id: "" }]);
  const [stateInput, setStateInput] = useState("");
  const [zipcodeInput, setZipcodeInput] = useState("");
  const [originalZipcodes, setOriginalZipcodes] = useState("");

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  const compareFunc = (a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return (dateA > dateB) - (dateA < dateB);
  };

  async function setZipcodes(zipcodes) {
    const zipcodeArray = zipcodes.items.map((zip) => zip.zipcode);
    setOriginalZipcodes(zipcodeArray);
    setZipcodeInput(
      zipcodeArray.reduce((str, val) => {
        if (str === "") {
          return val;
        } else {
          return str + ", " + val;
        }
      }, "")
    );
  }

  async function fetchStates() {
    try {
      // get States
      const stateData = await API.graphql(graphqlOperation(listStates));
      const stateList = stateData.data.listStates.items;
      stateList.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        if (a.id === b.id) {
          return 0;
        }
      });
      setStates(stateList);
    } catch (err) {
      console.log("error fetching states", err);
      setErrors([...errors, "Error fetching states"]);
    }
  }

  async function fetchCity(inputId) {
    try {
      const apiData = await API.graphql(
        graphqlOperation(getCity, { id: inputId })
      );
      const city = apiData.data.getCity;
      setFormState(city);
      setZipcodes(city.zipcode);
    } catch (err) {
      console.log("error fetching city", err);
      setErrors([...errors, "Error fetching city"]);
    }
  }

  useEffect(() => {
    if (id !== "new") {
      fetchCity(id);
    }
    fetchStates();
  }, []);

  const parseZips = (zipcodes) => {
    return zipcodes.split(/, ?/);
  };

  const arrEquals = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    return arr1.reduce((acc, item, ind) => {
      if (!acc) {
        return false;
      }
      return item === arr2[ind];
    }, true);
  };

  const findAddedZipcodes = () => {
    const newZips = parseZips(zipcodeInput) || [];
    const oldZips = originalZipcodes || [];
    if (arrEquals(oldZips, newZips)) {
      return [];
    }
    return newZips.filter((zip) => !oldZips.includes(zip));
  };

  const findRemovedZipcodes = () => {
    const newZips = parseZips(zipcodeInput) || [];
    const oldZips = originalZipcodes || [];
    if (arrEquals(oldZips, newZips)) {
      return [];
    }
    return oldZips.filter((zip) => !newZips.includes(zip));
  };

  const addCityToNewZipcodes = async (cityId, newZips) => {
    return newZips.map(async (zip) => {
      return API.graphql(
        graphqlOperation(updateZipcode, {
          input: {
            zipcode: zip,
            zipcodeCityId: cityId,
          },
        })
      );
    });
  };

  const removeCityFromOldZipcodes = async (cityId, oldZips) => {
    return oldZips.map(async (zip) => {
      return API.graphql(
        graphqlOperation(updateZipcode, {
          input: {
            zipcode: zip,
            zipcodeCityId: null,
          },
        })
      );
    });
  };

  const updateZipcodes = async (cityId) => {
    console.log("updating zipcodes");
    const addedZipcodes = findAddedZipcodes();
    const removedZipcodes = findRemovedZipcodes();
    console.log("adding:", addedZipcodes, "removing:", removedZipcodes);
    const addResults = await addCityToNewZipcodes(cityId, addedZipcodes);
    const removeResults = await removeCityFromOldZipcodes(
      cityId,
      removedZipcodes
    );
  };

  async function addOrUpdateCity() {
    try {
      if (!formState.name || !formState.state || !formState.url) {
        setErrors([...errors, "Missing required value"]);
      }
      const city = { ...formState };
      if (city.id !== "") {
        // Create/update City
        const cleanCity = {
          id: city.id,
          name: city.name,
          county: city.county,
          type: city.type,
          population: city.population,
          url: city.url,
          cityStateId: city.state.id,
        };
        const cityResult = await API.graphql(
          graphqlOperation(updateCity, {
            input: cleanCity,
          })
        );

        const zipResult = await updateZipcodes(cityResult.data.updateCity.id);
      } else {
        const newCity = {
          name: city.name,
          county: city.county,
          type: city.type,
          population: city.population,
          url: city.url,
          cityStateId: city.state.id,
        };
        const cityResult = await API.graphql(
          graphqlOperation(createCity, {
            input: newCity,
          })
        );

        const zipResult = await updateZipcodes(cityResult.data.createCity.id);
      }
      console.log("City updated or created successfully");
      navigate("/admin/cities");
    } catch (err) {
      console.log("error creating or updating city:", err);
      setErrors([...errors, "Error creating or updating city"]);
    }
  }

  const CreateOrSaveIcon = formState.id === "" ? CreateIcon : SaveIcon;
  const createOrSaveText = formState.id === "" ? "Create" : "Save";

  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h2">City - {formState.name}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Link to="/admin/cities">
            <Typography>Back to list</Typography>
          </Link>
          <ButtonGroup>
            <Button onClick={addOrUpdateCity} startIcon={<CreateOrSaveIcon />}>
              {createOrSaveText}
            </Button>
            <Button
              component={Link}
              startIcon={<CancelIcon />}
              to="/admin/cities"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid item>
          <TextField
            label="Name"
            required
            variant="filled"
            onChange={(event) => setInput("name", event.target.value)}
            className={classes.input}
            value={formState.name}
            placeholder="Name"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="County"
            variant="filled"
            onChange={(event) => setInput("county", event.target.value)}
            className={classes.input}
            value={formState.county}
            placeholder="County"
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            options={states}
            disableCloseOnSelect
            getOptionLabel={(option) => `${option?.id}`}
            getOptionSelected={(option, value) => option?.id == value?.id}
            value={formState.state}
            onChange={(event, newValue) => {
              console.log(formState.state);
              setInput("state", newValue);
            }}
            inputValue={stateInput}
            onInputChange={(event, newInputValue) =>
              setStateInput(newInputValue)
            }
            className={classes.state}
            renderInput={(params) => (
              <TextField {...params} label="State" variant="filled" />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Population"
            variant="filled"
            onChange={(event) => setInput("population", event.target.value)}
            className={classes.input}
            value={formState.population}
            placeholder="Population"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="URL"
            required
            variant="filled"
            onChange={(event) => setInput("url", event.target.value)}
            className={classes.input}
            value={formState.url}
            placeholder="URL"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Zipcode"
            required
            variant="filled"
            onChange={(event) => setZipcodeInput(event.target.value)}
            className={classes.input}
            value={zipcodeInput}
            placeholder="Zipcode"
          />
        </Grid>

        <Grid item xs={12}>
          <ButtonGroup>
            <Button onClick={addOrUpdateCity} startIcon={<CreateOrSaveIcon />}>
              {createOrSaveText}
            </Button>
            <Button
              component={Link}
              startIcon={<CancelIcon />}
              to="/admin/cities"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </form>
  );
};

export default App;
