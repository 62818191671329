import React, { useEffect, useState } from "react";
import { omit } from "lodash";
import Amplify, { Auth, API, graphqlOperation, S3Image } from "aws-amplify";
import { Link } from "gatsby-theme-material-ui";
import { useStaticQuery, graphql } from "gatsby";
import { navigate } from "@reach/router";
import {
  makeStyles,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  InputLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  AddBox as CreateIcon,
  Cancel as CancelIcon,
  CheckBoxOutlineBlank as CheckboxBlankIcon,
  CheckBox as CheckboxIcon,
  Refresh as RefreshIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { v4 as uuid } from "uuid";
import { S3ImageUpload, ImagesList } from "../ImageComponents";
import { listStates } from "../../../graphql/queries";

const onCreateAdImage = `
  subscription OnCreateAdImage($adId: ID!) {
    onAdCreatedAdImage(adId: $adId) {
      id
      adId
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      createdAt
      updatedAt
    }
  }
`;

const listAdImagesByAd = /* GraphQL */ `
  query ListAdImagesByAd(
    $adId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAdImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdImagesByAd(
      adId: $adId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bucket
        createdAt
        updatedAt
        thumbnail {
          key
          width
          height
        }
      }
      nextToken
    }
  }
`;

const getAd = /* GraphQL */ `
  query GetOneAd($id: ID!) {
    getAd(id: $id) {
      id
      title
      description
      url
      state {
        items {
          state {
            id
          }
          id
        }
      }
    }
  }
`;

const updateAd = /* GraphQL */ `
  mutation UpdateOneAd(
    $input: UpdateAdInput!
    $condition: ModelAdConditionInput
  ) {
    updateAd(input: $input, condition: $condition) {
      id
      title
      description
      url
      createdAt
      updatedAt
    }
  }
`;

const createAd = /* GraphQL */ `
  mutation CreateOneAd(
    $input: CreateAdInput!
    $condition: ModelAdConditionInput
  ) {
    createAd(input: $input, condition: $condition) {
      id
      title
      description
      url
    }
  }
`;

const linkToState = /* GraphQL */ `
  mutation CreateAdStateLink(
    $input: CreateAdStateLinkInput!
    $condition: ModelAdStateLinkConditionInput
  ) {
    createAdStateLink(input: $input, condition: $condition) {
      ad {
        id
      }
      state {
        id
      }
    }
  }
`;

const deleteAdStateLink = `
  mutation DeleteAdStateLink(
    $input: DeleteAdStateLinkInput!
    $condition: ModelAdStateLinkConditionInput
  ) {
    deleteAdStateLink(input: $input, condition: $condition) {
      id
      adId
      stateId
    }
  }
`;

const initialState = {
  id: "",
  title: "",
  description: "",
  url: "",
  stateIds: [],
};

const useStyles = makeStyles((theme) => ({
  container: {},
  select: {
    width: 250,
  },
  input: {
    width: "100%",
  },
}));

const App = ({ id }) => {
  // Fetch placeholder image for uploads
  const placeholderQuery = useStaticQuery(graphql`
    query PlaceholderImageQuery3 {
      file(relativePath: { eq: "upload_placeholder.png" }) {
        childImageSharp {
          fixed(width: 450) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const placeholderImage = placeholderQuery?.file?.childImageSharp;

  const classes = useStyles();
  const [formState, setFormState] = useState(initialState);
  const [originalState, setOriginalState] = useState(initialState);
  const [images, setImages] = useState([]);
  const [errors, setErrors] = useState([]);
  const [oldSubscription, setOldSubscription] = useState();
  const [states, setStates] = useState([{ id: "" }]);
  const [stateInput, setStateInput] = useState("");

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  async function fetchImages(adId) {
    console.log("In fetchImages");
    const listImages = await API.graphql(
      graphqlOperation(listAdImagesByAd, { adId })
    );
    const newImages = listImages?.data?.listAdImagesByAd?.items;
    console.log("Images related to Ad", newImages);
    setImages(newImages);
  }

  async function fetchAd(inputId) {
    try {
      console.log("In fetchAd");
      const adData = await API.graphql(
        graphqlOperation(getAd, { id: inputId })
      );
      const ad = adData.data.getAd;
      console.log("fetchAd", ad);
      const newAd = {
        id: ad.id,
        title: ad.title,
        description: ad.description,
        url: ad.url,
        stateIds: ad.state.items.map((item) => item.state),
      };
      setFormState(newAd);
      setOriginalState({ ...newAd, key: ad.state.items });
      console.log("formState: ", newAd);
    } catch (err) {
      console.log("error fetching ad", err);
      setErrors([...errors, "Error fetching ad"]);
    }
  }

  async function fetchStates() {
    try {
      console.log("In fetchStates");
      // get States
      const stateData = await API.graphql(graphqlOperation(listStates));
      const stateList = stateData.data.listStates.items;
      stateList.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        if (a.id === b.id) {
          return 0;
        }
      });
      setStates(stateList);
      console.log("stateList: ", stateList);
    } catch (err) {
      console.log("error fetching states", err);
      setErrors([...errors, "Error fetching states"]);
    }
  }

  useEffect(() => {
    if (id !== "new") {
      fetchAd(id);
      fetchImages(id);
    }
    fetchStates();
  }, []);

  async function addOrUpdateAd() {
    console.log("In addOrUpdateAd");
    try {
      if (!formState.title || !formState.description) {
        console.log("Error with form: Missing required value");
        setErrors([...errors, "Missing required value"]);
        throw errors;
      }
      const ad = {
        id: formState.id,
        title: formState.title,
        description: formState.description,
        url: formState.url,
      };
      console.log("ad: ", ad);
      // Create/update ad
      if (ad.id !== "") {
        const cleanAd = omit(ad, [
          "createdAt",
          "updatedAt",
          "images",
          "stateIds",
        ]);
        const adPromise = await API.graphql(
          graphqlOperation(updateAd, {
            input: cleanAd,
          })
        );

        const allResults = await Promise.all([adPromise]);
        console.log(allResults);
        const results = allResults[0];
        // link to states
        formState.stateIds.map(async (item) => {
          try {
            console.log(
              "searching for: ",
              item,
              originalState.stateIds.find((state) => state.id === item.id)
            );
            if (!originalState.stateIds.find((state) => state.id === item.id)) {
              console.log("item not found. adding...");
              const linkResult = await API.graphql(
                graphqlOperation(linkToState, {
                  input: {
                    adId: formState.id,
                    stateId: item.id,
                  },
                })
              );
              console.log("added successfully: ", item, linkResult);
            }
          } catch (err) {
            console.log("Error in linkToState: ", err);
            setErrors([...errors, "Error in linkToState"]);
          }
        });
        originalState.key.map(async (item) => {
          try {
            if (
              !formState.stateIds.find((state) => state.id === item.state.id)
            ) {
              console.log("deleting: ", item);
              const deleteResult = await API.graphql(
                graphqlOperation(deleteAdStateLink, {
                  input: {
                    id: item.id,
                  },
                })
              );
              console.log(item, deleteResult);
            }
          } catch (err) {
            console.log("Error in delete: ", err);
            setErrors([...errors, "Error in deleteAdStateLink"]);
          }
        });
      } else {
        const newAd = omit(ad, ["id", "images", "stateIds"]);
        const adResult = await API.graphql(
          graphqlOperation(createAd, {
            input: newAd,
          })
        );

        const results = adResult;
        // link to states
        formState.stateIds.map(async (item) => {
          console.log(item);
          const linkPromise = await API.graphql(
            graphqlOperation(linkToState, {
              input: {
                adId: results.data.createAd.id,
                stateId: item,
              },
            })
          );
          console.log(item, linkResult);
        });
      }

      console.log("ad updated or created successfully");
      navigate("/admin/ads");
    } catch (err) {
      console.log("error creating or updating ad:", err);
      setErrors([...errors, "Error creating or updating ad"]);
    }
  }

  const toggleStateIds = (selected) => {
    console.log("toggleStateIds", selected);
    if (selected === undefined) {
      return;
    }
    setInput("stateIds", selected);
  };

  const imagePlaceholderHandler = (enablePlaceholder, newImage = undefined) => {
    console.log("In imagePlaceholderHandler");
    if (enablePlaceholder) {
      setImages([
        ...images,
        { ...placeholderImage, placeholder: true, key: "placeholder" },
      ]);

      // Also start listening to new images added to the system
      onCreateImageSubscription();
    } else {
      const index = images.findIndex((item) => item.placeholder);
      const newImages = [...images.slice(0, index), ...images.slice(index + 1)];
      if (newImage) {
        newImages.push(newImage);
      }
      setImages(newImages);
    }
  };

  const removeNewAd = `
    mutation RemoveAdImage($imgID: ID) {
      deleteAdImage(input: {id: $imgID}) {
        id
      }
    }
  `;

  async function deleteImg(id) {
    console.log("id: ", id);
    const user = await Auth.currentAuthenticatedUser();
    console.log("user: ", user);
    const work = await API.graphql({
      ...graphqlOperation(removeNewAd, { imgID: id }),
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    console.log("response: ", work);
    return work;
  }

  const onRemoveHandler = async (imgId) => {
    console.log("In onRemoveHandler");
    const confirmation = await deleteImg(imgId);

    setImages(images.filter((item) => item.id !== imgId));
    return confirmation;
  };

  async function onCreateImageSubscription() {
    console.log("In onCreateImageSubscription()");
    const subscription = API.graphql(
      graphqlOperation(onCreateAdImage, { adId: id })
    ).subscribe({
      next: ({ provider, value }) => {
        imagePlaceholderHandler(false, value?.data?.onAdCreatedAdImage);
        if (value?.data?.onAdCreatedAdImage) {
          console.log("New image ready", { provider, value });
        } else {
          console.log("No new image?");
        }
      },
    });

    if (oldSubscription?.unsubscribe) {
      oldSubscription.unsubscribe();
    }

    setOldSubscription(subscription);
  }

  const CreateOrSaveIcon = formState.id === "" ? CreateIcon : SaveIcon;
  const createOrSaveText = formState.id === "" ? "Create" : "Save";
  const newWidth = "100";
  const DisplayImageUpload =
    formState.id === "" ? null : (
      <ButtonGroup>
        <S3ImageUpload
          id={{ id: formState.id, idName: "adId" }}
          uploadPlaceholderHandler={imagePlaceholderHandler}
          resizeWidth={newWidth}
        />
      </ButtonGroup>
    );

  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h2">Ad - {formState.title}</Typography>
        </Grid>

        <Grid item xs={12}>
          <ButtonGroup>
            <Button onClick={addOrUpdateAd} startIcon={<CreateOrSaveIcon />}>
              {createOrSaveText}
            </Button>
            <Button component={Link} startIcon={<CancelIcon />} to="/admin/ads">
              Cancel
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Title"
            required
            variant="filled"
            onChange={(event) => setInput("title", event.target.value)}
            className={classes.input}
            value={formState.title}
            placeholder="Title"
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={states}
            disableCloseOnSelect
            getOptionLabel={(option) => `${option?.id}`}
            getOptionSelected={(option, value) => option?.id === value?.id}
            value={formState.stateIds.map((item) =>
              states.find((state) => state.id === item.id)
            )}
            onChange={(event, newValue) => toggleStateIds(newValue)}
            inputValue={stateInput}
            onInputChange={(event, newInputValue) =>
              setStateInput(newInputValue)
            }
            className={classes.state}
            renderInput={(params) => (
              <TextField {...params} label="States" variant="filled" />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Description"
            required
            variant="filled"
            onChange={(event) => setInput("description", event.target.value)}
            className={classes.input}
            value={formState.description}
            placeholder="Description"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="URL"
            required
            variant="filled"
            onChange={(event) => setInput("url", event.target.value)}
            className={classes.input}
            value={formState.url}
            placeholder="URL"
          />
        </Grid>

        <Grid item xs={12}>
          {DisplayImageUpload}
        </Grid>
        <Grid item xs={12}>
          <ImagesList images={images} onRemove={onRemoveHandler} />
        </Grid>

        <Grid item xs={12}>
          <ButtonGroup>
            <Button onClick={addOrUpdateAd} startIcon={<CreateOrSaveIcon />}>
              {createOrSaveText}
            </Button>
            <Button component={Link} startIcon={<CancelIcon />} to="/admin/ads">
              Cancel
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </form>
  );
};

export default App;
