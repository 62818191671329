import React, { useEffect, useState } from "react";
import { omit } from "lodash";
import Amplify, { Auth, API, graphqlOperation, S3Image } from "aws-amplify";
import { Link } from "gatsby-theme-material-ui";
import { useStaticQuery, graphql } from "gatsby";
import { navigate } from "@reach/router";
import {
  makeStyles,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  InputLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  AddBox as CreateIcon,
  Cancel as CancelIcon,
  CheckBoxOutlineBlank as CheckboxBlankIcon,
  CheckBox as CheckboxIcon,
  Refresh as RefreshIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { S3ImageUpload, ImagesList } from "../ImageComponents";

const onCreateQuizImage = `
  subscription OnCreateQuizImage($questionId: ID!) {
    onQuestionCreatedQuizImage(questionId: $questionId) {
      id
      questionId
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      createdAt
      updatedAt
    }
  }
`;

const listQuizImagesByQuestion = /* GraphQL */ `
  query ListQuizImagesByQuestion(
    $questionId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuizImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizImagesByQuestion(
      questionId: $questionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionId
        bucket
        createdAt
        updatedAt
        thumbnail {
          key
          width
          height
        }
      }
      nextToken
    }
  }
`;

const getQuestion = /* GraphQL */ `
  query GetOneQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      question
      answers {
        answer
        answerExp
        correct
      }
      season
      createdAt
      updatedAt
    }
  }
`;

const updateQuestion = /* GraphQL */ `
  mutation UpdateOneQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      question
      answers {
        answer
        answerExp
        correct
      }
      season
      createdAt
      updatedAt
    }
  }
`;

const createQuestion = /* GraphQL */ `
  mutation CreateOneQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      question
      answers {
        answer
        answerExp
        correct
      }
      season
      createdAt
      updatedAt
    }
  }
`;

const initialState = {
  id: "",
  season: "Spring",
  question: "",
  answer1Ans: "",
  answer1Exp: "",
  answer1Correct: false,
  answer2Ans: "",
  answer2Exp: "",
  answer2Correct: false,
};

const useStyles = makeStyles((theme) => ({
  select: {
    width: 250,
  },
  input: {
    width: "100%",
  },
}));

const App = ({ id }) => {
  // Fetch placeholder image for uploads
  const placeholderQuery = useStaticQuery(graphql`
    query PlaceholderImageQuery2 {
      file(relativePath: { eq: "upload_placeholder.png" }) {
        childImageSharp {
          fixed(width: 450) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const placeholderImage = placeholderQuery?.file?.childImageSharp;

  const classes = useStyles();
  const [formState, setFormState] = useState(initialState);
  const [images, setImages] = useState([]);
  const [errors, setErrors] = useState([]);
  const [oldSubscription, setOldSubscription] = useState();

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
    console.log(formState.$key, "key", key);
  }

  async function fetchImages(questionId) {
    const listImages = await API.graphql(
      graphqlOperation(listQuizImagesByQuestion, { questionId })
    );
    const newImages = listImages?.data?.listQuizImagesByQuestion?.items;
    console.log("Images related to Question", newImages);
    setImages(newImages);
  }

  async function fetchQuestion(inputId) {
    try {
      const questionData = await API.graphql(
        graphqlOperation(getQuestion, { id: inputId })
      );
      const question = questionData.data.getQuestion;
      console.log("fetchQuestion", question);
      setFormState({
        id: question.id,
        season: question.season,
        question: question.question,
        answer1Ans: question.answers[0].answer,
        answer1Exp: question.answers[0].answerExp,
        answer1Correct: question.answers[0].correct,
        answer2Ans: question.answers[1].answer,
        answer2Exp: question.answers[1].answerExp,
        answer2Correct: question.answers[1].correct,
      });
      console.log("formState: ", formState);
    } catch (err) {
      console.log("error fetching question", err);
      setErrors([...errors, "Error fetching question"]);
    }
  }

  useEffect(() => {
    if (id !== "new") {
      fetchQuestion(id);
      fetchImages(id);
    }
  }, []);

  async function addOrUpdateQuestion() {
    try {
      if (
        !formState.question ||
        (!formState.answer1Correct && !formState.answer2Correct) ||
        !formState.season
      ) {
        console.log("Error with form");
        setErrors([...errors, "Missing required value"]);
        throw errors;
      }
      const newQuestion = {
        id: formState.id,
        season: formState.season,
        question: formState.question,
        answers: [
          {
            answer: formState.answer1Ans,
            answerExp: formState.answer1Exp,
            correct: formState.answer1Correct,
          },
          {
            answer: formState.answer2Ans,
            answerExp: formState.answer2Exp,
            correct: formState.answer2Correct,
          },
        ],
      };
      console.log("question: ", newQuestion);
      const isNewQuestion = newQuestion.id !== "";
      if (isNewQuestion) {
        // Create/update Question
        const questionPromise = await API.graphql(
          graphqlOperation(updateQuestion, {
            input: newQuestion,
          })
        );
      } else {
        const cleanQuestion = omit(newQuestion, ["id"]);
        const questionResult = await API.graphql(
          graphqlOperation(createQuestion, {
            input: cleanQuestion,
          })
        );
      }
      console.log("question updated or created successfully");
      navigate("/admin/questions");
    } catch (err) {
      console.log("error creating or updating question:", err);
      setErrors([...errors, "Error creating or updating question"]);
    }
  }

  const imagePlaceholderHandler = (enablePlaceholder, newImage = undefined) => {
    console.log("In imagePlaceholderHandler");
    if (enablePlaceholder) {
      setImages([
        ...images,
        { ...placeholderImage, placeholder: true, key: "placeholder" },
      ]);

      // Also start listening to new images added to the system
      onCreateImageSubscription();
    } else {
      const index = images.findIndex((item) => item.placeholder);
      const newImages = [...images.slice(0, index), ...images.slice(index + 1)];
      if (newImage) {
        newImages.push(newImage);
      }
      setImages(newImages);
    }
  };

  const removeQuizImage = `
    mutation RemoveQuizImage($imgID: ID) {
      deleteQuizImage(input: {id: $imgID}) {
        id
      }
    }
  `;

  async function deleteImg(id) {
    console.log("id: ", id);
    const user = await Auth.currentAuthenticatedUser();
    console.log("user: ", user);
    const work = await API.graphql({
      ...graphqlOperation(removeQuizImage, { imgID: id }),
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    console.log("response: ", work);
    return work;
  }

  const onRemoveHandler = async (imgId) => {
    const confirmation = await deleteImg(imgId);

    setImages(images.filter((item) => item.id !== imgId));
    return confirmation;
  };

  async function onCreateImageSubscription() {
    console.log("In onCreateImageSubscription()");
    const subscription = API.graphql(
      graphqlOperation(onCreateQuizImage, { questionId: id })
    ).subscribe({
      next: ({ provider, value }) => {
        imagePlaceholderHandler(false, value?.data?.onQuestionCreatedQuizImage);
        if (value?.data?.onQuestionCreatedQuizImage) {
          console.log("New image ready", { provider, value });
        } else {
          console.log("No new image?");
        }
      },
    });

    if (oldSubscription?.unsubscribe) {
      oldSubscription.unsubscribe();
    }

    setOldSubscription(subscription);
  }

  const CreateOrSaveIcon = formState.id === "" ? CreateIcon : SaveIcon;
  const createOrSaveText = formState.id === "" ? "Create" : "Save";
  const newWidth = "450";
  const DisplayImageUpload =
    formState.id === "" ? null : (
      <ButtonGroup>
        <S3ImageUpload
          id={{ id: formState.id, idName: "questionId" }}
          uploadPlaceholderHandler={imagePlaceholderHandler}
          resizeWidth={newWidth}
        />
      </ButtonGroup>
    );

  const seasons = ["Spring", "Summer", "Fall", "Winter"];

  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2">Question - {formState.question}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Link to="/admin/questions">
            <Typography>Back to list</Typography>
          </Link>
          <ButtonGroup>
            <Button
              onClick={addOrUpdateQuestion}
              startIcon={<CreateOrSaveIcon />}
            >
              {createOrSaveText}
            </Button>
            <Button
              component={Link}
              startIcon={<CancelIcon />}
              to="/admin/questions"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid item>
          <FormControl required variant="filled">
            <InputLabel id="season-label">Season</InputLabel>
            <Select
              required
              labelId="season-label"
              value={formState.season}
              onChange={(event) => setInput("season", event.target.value)}
              className={classes.select}
            >
              {seasons.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Question"
            required
            variant="filled"
            onChange={(event) => setInput("question", event.target.value)}
            className={classes.input}
            value={formState.question}
            placeholder="Question"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Answer 1"
            variant="filled"
            onChange={(event) => setInput("answer1Ans", event.target.value)}
            className={classes.input}
            value={formState.answer1Ans}
            placeholder="Answer 1"
            multiline
            rows={4}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Answer 1 Exp"
            variant="filled"
            onChange={(event) => setInput("answer1Exp", event.target.value)}
            className={classes.input}
            value={formState.answer1Exp}
            placeholder="Answer 1"
            multiline
            rows={4}
          />
        </Grid>

        <Grid item>
          <FormControl required variant="filled">
            <InputLabel id="Answer 1 Correct">Correct?</InputLabel>
            <Select
              required
              labelId="answer1-correct"
              value={formState.answer1Correct}
              onChange={(event) =>
                setInput("answer1Correct", event.target.value)
              }
              className={classes.select}
            >
              <MenuItem key="true" value={true}>
                true
              </MenuItem>
              <MenuItem key="false" value={false}>
                false
              </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Answer 2"
            variant="filled"
            onChange={(event) => setInput("answer2Ans", event.target.value)}
            className={classes.input}
            value={formState.answer2Ans}
            placeholder="Answer 2"
            multiline
            rows={4}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Answer 2 Exp"
            variant="filled"
            onChange={(event) => setInput("answer2Exp", event.target.value)}
            className={classes.input}
            value={formState.answer2Exp}
            placeholder="Answer 2"
            multiline
            rows={4}
          />
        </Grid>

        <Grid item>
          <FormControl required variant="filled">
            <InputLabel id="Answer 2 Correct">Correct?</InputLabel>
            <Select
              required
              labelId="answer2-correct"
              value={formState.answer2Correct}
              onChange={(event) => {
                setInput("answer2Correct", event.target.value);
                console.log(formState);
              }}
              className={classes.select}
            >
              <MenuItem key="true" value={true}>
                true
              </MenuItem>
              <MenuItem key="false" value={false}>
                false
              </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          {DisplayImageUpload}
        </Grid>
        <Grid item xs={12}>
          <ImagesList images={images} onRemove={onRemoveHandler} />
        </Grid>

        <Grid item xs={12}>
          <ButtonGroup>
            <Button
              onClick={addOrUpdateQuestion}
              startIcon={<CreateOrSaveIcon />}
            >
              {createOrSaveText}
            </Button>
            <Button
              component={Link}
              startIcon={<CancelIcon />}
              to="/admin/questions"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </form>
  );
};

export default App;
