import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import {
  makeStyles,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  AddBox as AddBoxIcon,
  Edit as EditIcon,
  DeleteForever as DeleteIcon,
} from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import { Link } from "gatsby-theme-material-ui";

const listAds = `
  query ListAds(
    $filter: ModelAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        state {
          items {
            stateId
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

const deleteAd = `
  mutation DeleteAd(
    $input: DeleteAdInput!
    $condition: ModelAdConditionInput
  ) {
    deleteCity(input: $input, condition: $condition) {
      id
      title
      description
      states {
        items {
          stateId
        }
      }
      createdAt
      updatedAt
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
  },
  addNewContainer: {
    textAlign: "right",
  },
  addNew: {
    margin: "0.75rem 0",
  },
  table: {
    minWidth: 300,
  },
  edit: {},
  remove: {
    backgroundColor: theme.palette.error.dark,
  },
}));

const App = () => {
  const classes = useStyles();
  const [ads, setAds] = useState([]);
  const [errors, setErrors] = useState([]);

  async function fetchAds() {
    try {
      const adData = await API.graphql(graphqlOperation(listAds));
      const fetchedAds = adData.data.listAds.items;
      console.log(fetchedAds);
      setAds(fetchedAds);
    } catch (err) {
      console.log("Error fetching ads: ", err);
      setErrors([...errors, "Error fetching ads"]);
    }
  }

  useEffect(() => {
    fetchAds();
  }, []);

  async function removeAd(id) {
    if (typeof id === "undefined") {
      return;
    }
    try {
      const results = await API.graphql(
        graphqlOperation(deleteAd, { input: { id } })
      );
      console.log("removeAds:", results);

      const newAds = ads.filter((item) => item.id !== id);
      setAds(newAds);
    } catch (err) {
      console.log("error removing ad:", err);
      setErrors([...errors, "Error removing ad"]);
    }
  }

  return (
    <Grid container justify="space-between" className={classes.container}>
      <Grid item xs={6}>
        <Typography variant="h2">Ads</Typography>
      </Grid>
      <Grid item xs={4} className={classes.addNewContainer}>
        <Button
          className={classes.addNew}
          variant="contained"
          startIcon={<AddBoxIcon />}
          component={Link}
          to="/admin/ads/new"
        >
          Add new item
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="list of ads">
            <TableBody>
              {ads.map((ad) => (
                <TableRow key={ad.id}>
                  <TableCell>
                    <Typography>{ad.title}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {ad.state.items.map((item, ind) => {
                        var result = item.stateId;
                        if (ind !== ad.state.items.length - 1) {
                          result = result + ", ";
                        }
                        return result;
                      })}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <ButtonGroup>
                      <Button
                        className={classes.edit}
                        startIcon={<EditIcon />}
                        onClick={() => navigate(`/admin/ads/${ad.id}`)}
                      >
                        Edit
                      </Button>
                      <IconButton
                        className={classes.remove}
                        onClick={() => {
                          removeAd(ad.id);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default App;
