import React from "react";
import { Router } from "@reach/router";
import Layout from "../components/admin/layout";
import { Todo, TodoList } from "../components/admin/Todos";
import { Category, CategoryList } from "../components/admin/Categories";
import { Question, QuestionList } from "../components/admin/Questions";
import { Explanation, ExplanationList } from "../components/admin/Explanations";
import { Ad, AdList } from "../components/admin/Ads";
import { Seasons, SeasonsList } from "../components/admin/Seasons";
import { MissingZip } from "../components/admin/MissingZip";
import { City, CityList } from "../components/admin/City";
import Profile from "../components/admin/Profile";
import PrivateRoute from "../components/admin/PrivateRoute";
import Login from "../components/admin/Login";

const Admin = () => (
  <Layout>
    <Router>
      <Login path="/admin/login" />
      <PrivateRoute path="/admin/profile" component={Profile} />
      <PrivateRoute path="/admin/todos" component={TodoList} />
      <PrivateRoute path="/admin/todos/:id" component={Todo} />
      <PrivateRoute path="/admin/categories" component={CategoryList} />
      <PrivateRoute path="/admin/categories/:id" component={Category} />
      <PrivateRoute path="/admin/questions" component={QuestionList} />
      <PrivateRoute path="/admin/questions/:id" component={Question} />
      <PrivateRoute path="/admin/explanations" component={ExplanationList} />
      <PrivateRoute path="/admin/explanations/:id" component={Explanation} />
      <PrivateRoute path="/admin/missingzips" component={MissingZip} />
      <PrivateRoute path="/admin/ads" component={AdList} />
      <PrivateRoute path="/admin/ads/:id" component={Ad} />
      <PrivateRoute path="/admin/seasons" component={SeasonsList} />
      <PrivateRoute path="/admin/seasons/:id" component={Seasons} />
      <PrivateRoute path="/admin/cities" component={CityList} />
      <PrivateRoute path="/admin/cities/:id" component={City} />
      <PrivateRoute path="/admin" component={TodoList} />
    </Router>
  </Layout>
);

export default Admin;
