import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  AddBox as AddBoxIcon,
  Edit as EditIcon,
  DeleteForever as DeleteIcon,
} from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import { Link } from "gatsby-theme-material-ui";
import { deleteCategory } from "../../../graphql/mutations";
import { listCategorys } from "../../../graphql/queries";

const useStyles = makeStyles((theme) => ({
  container: {
  },
  addNewContainer: {
    textAlign: "right",
  },
  addNew: {
    marginTop: "1.5rem",
  },
  table: {
    minWidth: 300,
  },
  edit: {},
  remove: {
    backgroundColor: theme.palette.error.dark,
  },
}));

const App = () => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState([]);

  async function fetchCategories() {
    try {
      const categoryData = await API.graphql(graphqlOperation(listCategorys));
      const list = categoryData.data.listCategorys.items;
      console.log(list);
      setCategories(list);
    } catch (err) {
      console.log("error fetching categories");
      setErrors([...errors, "Error fetching categories"]);
    }
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  // @TODO: add confirmation modal
  async function removeCategory(id) {
    if (typeof id === "undefined") {
      return;
    }
    try {
      const results = await API.graphql(
        graphqlOperation(deleteCategory, { input: { id } })
      );
      console.log("removeCategory", results);

      const newCategories = categories.filter((item) => item.id !== id);
      setCategories(newCategories);
    } catch (err) {
      console.log("error removing category:", err);
      setErrors([...errors, "Error removing category"]);
    }
  }

  return (
    <Grid container justify="space-between" className={classes.container}>
      <Grid item xs={6}>
        <Typography variant="h2">Categories</Typography>
      </Grid>
      <Grid item xs={4} className={classes.addNewContainer}>
        <Button
          className={classes.addNew}
          variant="contained"
          startIcon={<AddBoxIcon />}
          component={Link}
          to="/admin/categories/new"
        >
          Add new item
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="list of categories">
            <TableBody>
              {categories.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Typography>{item.name}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <ButtonGroup>
                      <Button
                        className={classes.edit}
                        startIcon={<EditIcon />}
                        component={Link}
                        to={`/admin/categories/${item.id}`}
                      >
                        Edit
                      </Button>
                      <IconButton
                        className={classes.remove}
                        onClick={() => {
                          removeCategory(item.id);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default App;
