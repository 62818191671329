import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import {
  makeStyles,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  AddBox as AddBoxIcon,
  Edit as EditIcon,
  DeleteForever as DeleteIcon,
} from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import { Link } from "gatsby-theme-material-ui";
import { deleteCity } from "../../../graphql/mutations";
export const listCitys = /* GraphQL */ `
  query CustomListCitys(
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        county
        type
        population
        url
        createdAt
        updatedAt
        state {
          id
          name
          createdAt
          updatedAt
        }
        zipcode {
          items {
            zipcode
          }
        }
      }
      nextToken
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
  },
  addNewContainer: {
    textAlign: "right",
  },
  addNew: {
    margin: "0.75rem 0",
  },
  table: {
    minWidth: 300,
  },
  edit: {},
  remove: {
    backgroundColor: theme.palette.error.dark,
  },
}));

const App = () => {
  const classes = useStyles();
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState([]);

  async function fetchCities() {
    try {
      const apiData = await API.graphql(graphqlOperation(listCitys));
      const fetchedCities = apiData.data.listCitys.items;
      setCities(fetchedCities);
    } catch (err) {
      console.log("Error fetching cities: ", err);
      setErrors([...errors, "Error fetching cities"]);
    }
  }

  useEffect(() => {
    fetchCities();
  }, []);

  async function removeCity(id) {
    if (typeof id === "undefined") {
      return;
    }
    try {
      const results = await API.graphql(
        graphqlOperation(deleteCity, { input: { id } })
      );
      console.log("removeCity:", results);

      const newCities = cities.filter((item) => item.id !== id);
      setCities(newCities);
    } catch (err) {
      console.log("error removing city:", err);
      setErrors([...errors, "Error removing city"]);
    }
  }

  return (
    <Grid container justify="space-between" className={classes.container}>
      <Grid item xs={6}>
        <Typography variant="h2">Cities</Typography>
      </Grid>
      <Grid item xs={4} className={classes.addNewContainer}>
        <Button
          className={classes.addNew}
          variant="contained"
          startIcon={<AddBoxIcon />}
          component={Link}
          to="/admin/cities/new"
        >
          Add new item
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="list of cities">
            <TableBody>
              {cities.map((city) => (
                <TableRow key={city?.id}>
                  <TableCell>
                    <Typography>{city?.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{city?.state?.id}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {city?.zipcode?.items?.reduce((acc, item) => {
                        if (acc === "") {
                          return item.zipcode;
                        } else {
                          return acc + ", " + item.zipcode;
                        }
                      }, "")}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <ButtonGroup>
                      <Button
                        className={classes.edit}
                        startIcon={<EditIcon />}
                        onClick={() => navigate(`/admin/cities/${city?.id}`)}
                      >
                        Edit
                      </Button>
                      <IconButton
                        className={classes.remove}
                        onClick={() => {
                          removeCity(city?.id);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default App;
