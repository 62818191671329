import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import {
  makeStyles,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  AddBox as AddBoxIcon,
  Edit as EditIcon,
  DeleteForever as DeleteIcon,
} from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import { Link } from "gatsby-theme-material-ui";

const listSeasonsDates = `
  query ListSeasonsDates {
    listSeasonss {
      items {
        id
        spring {
          month
          day
        }
        summer {
          month
          day
        }
        fall {
          month
          day
        }
        winter {
          month
          day
        }
      }
    }
  }
`;

const deleteSeasons = `
  mutation DeleteSeasonsData(
    $input: DeleteSeasonsInput!
    $condition: ModelSeasonsConditionInput
  ) {
    deleteSeasons(input: $input, condition: $condition) {
      id
      spring {
        month
        day
      }
      summer {
        month
        day
      }
      fall {
        month
        day
      }
      winter {
        month
        day
      }
      createdAt
      updatedAt
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
  },
  addNewContainer: {
    textAlign: "right",
  },
  addNew: {
    margin: "0.75rem 0",
  },
  table: {
    minWidth: 300,
  },
  edit: {},
  remove: {
    backgroundColor: theme.palette.error.dark,
  },
}));

const App = () => {
  const classes = useStyles();
  const [seasons, setSeasons] = useState([]);
  const [errors, setErrors] = useState([]);
  const showNewButton = (() => {
    return seasons.length === 0;
  })();

  async function fetchSeasons() {
    try {
      const seasons = await API.graphql(graphqlOperation(listSeasonsDates));
      const fetchedSeasons = seasons.data.listSeasonss.items;
      console.log(fetchedSeasons);
      setSeasons(fetchedSeasons);
    } catch (err) {
      console.log("Error fetching seasons: ", err);
      setErrors([...errors, "Error fetching seasons"]);
    }
  }

  useEffect(() => {
    fetchSeasons();
  }, []);

  async function removeSeasons(id) {
    if (typeof id === "undefined") {
      return;
    }
    try {
      const results = await API.graphql(
        graphqlOperation(deleteSeasons, { input: { id } })
      );
      console.log("removeSeasons:", results);

      const newSeasons = seasons.filter((item) => item.id !== id);
      setSeasons(newSeasons);
    } catch (err) {
      console.log("error removing seasons:", err);
      setErrors([...errors, "Error removing seasons"]);
    }
  }

  return (
    <Grid container justify="space-between" className={classes.container}>
      <Grid item xs={6}>
        <Typography variant="h2">Custom Seasons</Typography>
        <Typography variant="subtitle1">
          It is recommended to keep these clear outside of testing
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.addNewContainer}>
        {showNewButton && (
          <Button
            className={classes.addNew}
            variant="contained"
            startIcon={<AddBoxIcon />}
            component={Link}
            to="/admin/seasons/new"
          >
            Add new item
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="list of seasons">
            <TableBody>
              {seasons.map((season) => (
                <TableRow key={seasons.id}>
                  <TableCell>
                    <Typography>{season.id}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <ButtonGroup>
                      <Button
                        className={classes.edit}
                        startIcon={<EditIcon />}
                        onClick={() => navigate(`/admin/seasons/${season.id}`)}
                      >
                        Edit
                      </Button>
                      <IconButton
                        className={classes.remove}
                        onClick={() => {
                          removeSeasons(season.id);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default App;
